;(function($){
  
  var playlistEditor, dragEl, dropEl, dragY, dropAlign;
  var playlistTable;

  function init(){
    playlistEditor = $(".playlist-editor.editable");
    playlistTable = $("table.playlists");

    if(playlistEditor.length > 0){
      initPlaylistDnD();
      return;
    }
    
    if(playlistTable.length > 0){
      initPlaylistTable();
    }
  }

  function initPlaylistTable(e){
    var playlistTypeSelects = playlistTable.find("select[name='playlist_type']");
    playlistTypeSelects.on("change", updatePlaylistType);
  }

  function updatePlaylistType(e){
    var $this = $(this);
    var td = $this.parent("td"); 
    var playlistId = $this.parents("tr").attr("data-index");
    $this.attr({"disabled": "disabled"});
    td.addClass("updating");
    var data = {action: "update_playlist_type", playlistType: this.value , playlistId:playlistId};
    $.post(ajaxurl, data, function(res){
      $this.removeAttr("disabled");
      td.removeClass("updating");
    });
  }

  function addDndEvents(jqObject){
    jqObject.on("dragstart", onDragStart);
    jqObject.on("dragenter", onDragEnter);
    jqObject.on("dragover", onDragOver);
    jqObject.on("dragleave", onDragLeave);
    jqObject.on("drop", handleDrop);
    jqObject.on("dragend", onDragEnd);
  }

  function removeDndEvents(jqObject){
    jqObject.off("dragstart", onDragStart);
    jqObject.off("dragenter", onDragEnter);
    jqObject.off("dragover", onDragOver);
    jqObject.off("dragleave", onDragLeave);
    jqObject.off("drop", handleDrop);
    jqObject.off("dragend", onDragEnd);
  }

  function initPlaylistDnD(){

    addDndEvents($("article", playlistEditor));
  }

  function onDragStart(e){
    dragEl = $(this);
    var offset = dragEl.offset();
    dragY = e.originalEvent.pageY - offset.top - (dragEl.outerHeight() / 2);
    e.originalEvent.dataTransfer.effectAllowed = 'move';
    e.originalEvent.dataTransfer.setData('index', dragEl.attr("data-index"));
  }

  function onDragEnter(e){
    e.preventDefault();
    e.stopPropagation();
  }

  function onDragOver(e){
    e.preventDefault();
    e.stopPropagation();
    if(typeof dropEl !== "undefined"){
      dropEl.removeClass("drop-target");
    }
    dropEl = $(this);
    var offset = dropEl.offset();
    var height = dropEl.outerHeight();

    var y = e.originalEvent.pageY  - offset.top - dragY;

    if(y >= height/2){
      dropAlign = "bottom";
      dropEl.removeClass("drop-target-top");
    } else {
      dropAlign = "top";
      dropEl.removeClass("drop-target-bottom");
    }
    
    dropEl.addClass("drop-target drop-target-" + dropAlign);
  }

  function onDragLeave(e){
    e.preventDefault();
    e.stopPropagation();
    dropEl.removeClass("drop-target");
  }

  function handleDrop(e){
    e.preventDefault();
    e.stopPropagation();

    var oldOrder = getSongOrder();   

    //console.log(oldOrder);

    if(dragEl.get(0) === dropEl.get(0)){
      return;
    }

    if(dropAlign === "top"){
      dragEl.insertBefore(dropEl);
    } else {
      dragEl.insertAfter(dropEl);
    }

    var newOrder = getSongOrder();
    
    if(isArrayEqual(oldOrder, newOrder)){
      return;
    }

    //removeDndEvents($("li", playlistEditor));

    $("h3.playlist-title small").addClass("loading");
    var data = {action: "update_playlist", songs: toSongFormdata(newOrder), playlistId:$(".playlist-editor").attr("data-playlist")};
    $.post(ajaxurl, data, function(response){
      $("h3.playlist-title small").removeClass("loading");
      //console.log(response);
      //addDndEvents($("li", playlistEditor));
    });
    
  }

  function onDragEnd(){
    
    $("article", playlistEditor).removeClass("drop-target");
    dragEl.addClass("interaction-complete");
    
  }

  function getSongOrder(){
    var order = [];
    var lis = $("article", playlistEditor);
    lis.each(function(i,el){
      order.push(el.getAttribute("data-index"));
    });
    return order;
  }

  function toSongFormdata(songs){
    var formData = {};
    for(var i = 0; i < songs.length; i++){
      var song = songs[i];
      formData[song.toString()] = i+1;
    }
    return formData;
  }

  function isArrayEqual(firstArray, secondArray){
    if(firstArray.length !== secondArray.length){
      return false;
    }
    for(var i = 0; i < firstArray.length; i++){
      var elA = firstArray[i];
      var elB = secondArray[i];
      if(elA !== elB){
        return false;
      }
    }
    return true;
  }

  function loadPlaylist(){
    var id = $(this).attr("data-id");
    var data = {playlistId: id, action: "get_songs"};
    $.post(ajaxurl, data, function(response){
      var html = "";
      if(response.length === 0){
        html += "<p>No songs yet!</p>";
      } else {
        html += "<ol>";
        response.forEach(function(el, i){
          html += "<li><a href='" + el.link + "'>" + el.title + "</a></li>";
        });
        html += "</ol>";
      }
      $("#songlist").html(html);
    });
    return false;
  }

  $(init);

})(jQuery);