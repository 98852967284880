/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  $(document).ready(function(){
    init();
  });

  $(document).on('facetwp-loaded', function(){
    $(".song-listing > div").matchHeight();
    markCapabilities();
  });

  $(document).on('facetwp-refresh', function(){
    markCapabilities();
  });

  function init(){
    $(".song-listing > div").matchHeight();
    
  }

  function markCapabilities(){

    if(typeof caps !== "undefined"){
      var songbookFacets = $(".facetwp-facet-song_books");
      $("span.glyphicon", songbookFacets).remove();
      caps.forEach(function(el, i){
        var bookId = el.match(/can_read_(.*)/);
        if(bookId && typeof bookId[1] !== "undefined"){
          var selector = "div[data-value='" + bookId[1] + "'] label";
          $(selector, songbookFacets).append("<span class='glyphicon glyphicon-ok'></span>");
        }
      });
    }
  }


})(jQuery); // Fully reference jQuery after this point.
