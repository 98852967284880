;(function($){

  var currentSongTitle;
  var currentSongId;
  var processing = false;

  $(document).on('facetwp-loaded', function(){
      addListeners();
  });

  function init(){
    addListeners();
    
    $(".close-modal").on("click", function(){
      $("#modal").modal("hide");
      $("#modal div.add-playlist").hide();
      hideAlert();
    });
    $("#modal div.add-playlist").hide();
    $("#modal button.btn-create").on("click", showPlaylistCreator);
    $("#modal form#create-playlist").on("submit", validateCreateForm);
    $("#modal").modal({show:false});
    $("#modal").on("show.bs.modal", clearFields);
    
    $("#modal .alert button.close").on("click", hideAlert);
  }

  function hideAlert(){
    $("#modal .alert").addClass("hide");
    $("#modal .alert").removeClass("show");
  }

  function showPlaylistCreator(e){
    $("#modal div.add-playlist").slideDown(300);
  }

  function clearFields(){
    var modal = $("#modal");
    $("input[type='text']", modal).val("");
    $("select", modal).val([]);

  }

  function validateCreateForm(){
    if(processing === true){
      return;
    }
    processing = true;
    $("#create-playlist button.submit").addClass("disabled");

    var data = {"action": "create_playlist"};
    var playlistName = $("#playlistName").val();
    var playlistType = $("input[name='type']:checked").val();
    var organisationName = $("#organisationName").val();

    var themes = $("select#themes").val();
    var message = "", status;

    data.playlistName = playlistName;
    data.playlistType = playlistType;
    data.organisationName = organisationName;
    data.themes = themes;
    data.songId = currentSongId;

    $.post(ajaxurl, data, function(response){
      if(typeof response.error !== "undefined"){
        status = "danger";
        switch(response.error){
          case "bad_request":
            if(typeof response.messages !== "undefined"){
              for(var prop in response.messages) {
                switch(prop){
                  case "PlaylistName":
                    message += "<p>Song list name must be between 4 and 60 characters.</p>";
                  break;
                  case "PlaylistType":
                    message += "<p>Please choose a valid Song list type (public or group)</p>";
                  break;
                  case "OrganisationName":
                    message += "<p>Organisation must be between 4 and 80 characters.</p>";
                  break;
                  default:
                    message += "<p>An unknown validation error occurred.</p>";
                }
              }
            }
          break;
          default:
          message = "Unknown Error";
        }
      } else {
        loadContent();
        message = "Songlist created and song added.";
        status = "success";
        clearFields();

      }

      modalAlert(message, status);
      $("#create-playlist button.submit").removeClass("disabled");
      processing = false;

    });
    return false;
  }

  function modalAlert(message, alertClass){
    $("#modal .alert").removeClass("alert-danger alert-success");
    $("#modal .alert strong").html(message);
    $("#modal .alert").addClass("show" + " alert-" + alertClass);
    $("#modal .alert.hide").removeClass("hide");
  }

  function addListeners(){
    var buttons = $(".add-to-playlist");
    buttons.on("click", launchPlaylistEditor);
  }

  function launchPlaylistEditor(e){
    var article = $(this).parents("article");
    currentSongTitle = $(".title-text", article).text();
    currentSongId = article.attr("data-id");
    $("em.song-name").text(currentSongTitle);
    $("#modal").modal("show");
    loadContent();
    return false;
  }

  function loadContent(){
    var data = {"action": "get_playlists"};
    $("#modal .loading").removeClass("hide");
    $.post(ajaxurl, data, function(data){

      $("#modal .loading").addClass("hide");
      if(typeof data.playlists !== "undefined" && data.playlists.length > 0){
        var select = "<select class='playlists'><option value=''>Choose songlist</option>";
        $.each(data.playlists, function(i, el){
          select += "<option value='" + el.id+ "'>" + trim(el.name, 30) + "</option>";
        });
        select += "</select>";
        $("#modal .playlists").html(select);
        $("select.playlists").on("change", checkPlaylistStatus);
      } else {
        $("#modal .playlists").html("<p>You haven't made any songlists yet.");
      }
    });
  }

  function trim(str, len){
    if(str.length <= len){
      return str;
    }
    return str.substr(0,len) + "..";
  }

  function addToPlaylist(){
    var data = {action: "add_to_playlist", playlistId: $("select.playlists").val(), songId: currentSongId};
    $.post(ajaxurl, data, function(response){

      var message;
      var status;

      if(typeof response.error !== "undefined"){
        // uh-oh..
        status = "danger";
        switch(response.error){
          case "song_exists":
          message = "<em>" + currentSongTitle + "</em> has already been added to this songlist.";
          break;
          default:
          message = "Unknown error";
        }

      } else if(response === 1) {
        status = "success";
        message = "<em>" + currentSongTitle + "</em> was added to the songlist.";
      }
      modalAlert(message, status);
    });
  }

  function checkPlaylistStatus(){
    var button = $("#modal button.btn-save");
    if($(this).val() !== ""){
      if(button.hasClass("disabled")){
        button.removeClass("disabled");
        button.on("click", addToPlaylist);
      }
    }  else {
      if(!button.hasClass("disabled")){
        button.addClass("disabled");
        button.off("click", addToPlaylist);
      }
    }
  }

  $(init);

})(jQuery);
